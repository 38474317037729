import React, {createContext, useEffect, useState} from "react";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import Modal from "../Components/Modal";
import {ModalProvider} from "../Utils/ModalsContext";
import HomePage from "../Pages/HomePage";
import AOS from "aos";
import "aos/dist/aos.css";
import {BrowserRouter, Routes, Route, Redirect,} from "react-router-dom";
import Profile from "../Pages/Profile";
import {LoginProvider} from "../Utils/LoginContext";


function App() {
    const [isModalOpen, setModalOpen] = useState(false)
    const [isVerifiedLogin, setVerifiedLogin] = useState(false)
    const [walletInfo, setWalletInfo] = useState(null)
    const [userData, setUserData] = useState(null)

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (<>
            <div className={"App"}>
                <LoginProvider value={{isVerifiedLogin, setVerifiedLogin}}>
                    <ModalProvider
                        value={{
                            isModalOpen, setModalOpen, setUserData, userData,
                            setWalletInfo, walletInfo
                        }}>

                        <main className={"content-container scrollable"}>

                            <Routes>
                                {isVerifiedLogin ?
                                    <Route exact path="/profile" element={<Profile/>}/>

                                    :
                                    <Route exact path="/homepage" element={<HomePage/>}/>
                                }
                                <Route exact path="/*" element={<HomePage/>}/>
                            </Routes>


                        </main>

                        {/*Modal Containers*/}
                        <TransitionGroup>
                            <CSSTransition
                                key={isModalOpen}
                                classNames="fade"
                                timeout={300}
                            >
                                <>
                                    {isModalOpen &&
                                        <Modal openState={isModalOpen} setState={setModalOpen} userData={userData}/>}
                                </>

                            </CSSTransition>
                        </TransitionGroup>
                    </ModalProvider>
                </LoginProvider>
            </div>

        </>

    );
}

export default App;