let apiURL = "https://apis.arab-watches.com"
// let apiURL = "http://192.168.1.15/fadi_apis"


let pullData = {
    verify_customer: (formdata) => {
        return fetch(`${apiURL}/verify_transaction.php`, {
            method: 'post',
            body: formdata
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
    getUserDetails: (userID) => {
        return fetch(`${apiURL}/get_user.php`, {
            method: 'post',
            body: userID
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
    verifyCode: (data) => {
        return fetch(`${apiURL}/verify_code.php`, {
            method: 'post',
            body: data
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
    getWatchInfo: (data) => {
        return fetch(`${apiURL}/get_watch.php`, {
            method: 'post',
            body: data
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
}

export default pullData;
