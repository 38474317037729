import "./Modal.css"
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import pullData from "../Utils/API";
import loginContext from "../Utils/LoginContext";

export default function Modal({openState, setState, userData, setLogin}) {
    let navigate = useNavigate();
    const [vcode, setVcode] = useState("")
    const {setVerifiedLogin} = useContext(loginContext)

    const obscureEmail = email => {
        const [name, domain] = email.split("@");
        return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
    };

    function handleCodeVerification() {
        let formData = new FormData()

        formData.append("user_id", userData.id)
        formData.append("code", vcode)

        pullData.verifyCode(formData).then(r => {
            console.log(r)

            // setVerifiedLogin(true)
            // setState(false)
            // navigate(`/profile`);

            if (r.success === false) {
                //false code
                alert(r.message)
            } else {
                //true code
                setVerifiedLogin(true)
                setState(false)
                navigate(`/profile`);
            }
        })
    }

    return (<>
        <div className={"modal-wrapper"}>
            <div className="modal-body">
                <div className={"modal-content border-0 p-md-4"}>
                    <div className={"container"}>
                        <h2 className={"h2 fw-lighter"}>Verify your account.</h2>
                        <span className={"fw-lighter"}>
                            Enter code sent to your Email&nbsp;
                            <span> {obscureEmail(userData.email)}</span>
                        </span>
                        <br/>
                        <br/>
                        <div className={"row my-2"}>
                            <div className="col-md-6 mb-3">
                                <input type="text" placeholder={"Enter verification code"}
                                       className={"form-control"}
                                       onChange={(e) => {
                                           setVcode(e.target.value)
                                       }}/>
                            </div>

                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center justify-content-end">
                            <div className="col-md-6 mb-3 d-flex align-items-center justify-content-end">
                                <button className={"custom-pill-button btn rounded-pill no-focus px-5"}
                                        onClick={() => setState(false)}>Cancel
                                </button>
                                <button className={"custom-pill-button btn btn-dark rounded-pill px-5"}
                                        onClick={() => {
                                            handleCodeVerification()
                                        }}>
                                    submit
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </>)
}