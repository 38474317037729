import logo from "../Assets/Frame.png"
import React, { useContext, useEffect, useState } from "react";
import ModalsContext from "../Utils/ModalsContext";
import a22 from "../Assets/A22Logo.png"
import ReactLoading from 'react-loading';
import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
import abi from './../abi.json';
import pullData from "../Utils/API";
import { Form } from "reactstrap";
import LoginContext from "../Utils/LoginContext";

require("dotenv").config();

const { REACT_APP_CONTRACT_ADDRESS } = process.env;

const SELECTEDNETWORK = "1";
const SELECTEDNETWORKNAME = "Ethereum";
const nftquantity = 50;

export default function HomePage() {
    const { setModalOpen, setUserData, userData, setWalletInfo } = useContext(ModalsContext)
    // -------------- >

    const [showConnectWallet, setShowConnectWallet] = React.useState(false);
    const [errormsg, setErrorMsg] = useState(false);
    // const [quantityNFT, setQuantityNFT] = useState(1);
    const [account, setAccount] = useState('')
    const [isConnected, setisConnected] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const { setVerifiedLogin } = useContext(LoginContext)


    async function connectMetaMask() {
        // if (!window.ethereum) {
        //     window.location.assign("https://metamask.app.link/dapp/")
        //     return
        // }

        if (process.browser) {
            const ethereum = window.ethereum;
        }

        await window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then(handleAccountsChanged)
            .catch((err) => {
                if (err.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    // If this happens, the user rejected the connection request.
                    console.log("Please connect to MetaMask.-----");
                    setErrorMsg("Please connect to MetaMask.");
                } else {
                    console.error(err);
                }
            });
    }

    async function handleAccountsChanged(accounts) {
        if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts
            console.log("Please connect to MetaMask.");
            setErrorMsg("Please connect to MetaMask.");

        } else if (accounts[0] !== account) {
            let metaMaskAccount = accounts[0];
            console.log(metaMaskAccount);
            if (metaMaskAccount) {
                setAccount(metaMaskAccount);
                fetch(metaMaskAccount);
            } else {
                setErrorMsg("Connect Your wallet");
            }
        } else {
            let metaMaskAccount = accounts[0];
            setAccount(metaMaskAccount);
            fetch(metaMaskAccount);
        }
    }

    async function fetch(metamakAcc) {
        setisLoading(true)
        window.web3 = new Web3(window.ethereum);
        const web3 = window.web3;
        if (await web3.eth.net.getId() == SELECTEDNETWORK) {

            const contractaddress = REACT_APP_CONTRACT_ADDRESS;
            const ct = new web3.eth.Contract(abi, contractaddress);
            const isHolder = await ct.methods.balanceOf(metamakAcc).call()
            if (isHolder >= 1) {
                let formData = new FormData()
                formData.append("wallet_address", metamakAcc)

                pullData.verify_customer(formData).then(r => {
                    //Got transaction here
                    console.log(r)

                    if (r.success === true) {
                        setisConnected(true)
                        setAccount(metamakAcc)
                        setWalletInfo(r.transaction_data)

                        r.transaction_data.forEach(d => {
                            console.log(d)
                        })

                        let formData = new FormData();
                        formData.append("user_id", r.transaction_data[0].user_id)

                        pullData.getUserDetails(formData).then(r => {
                            console.log(r)
                            if (r.success === true) {
                                setUserData(r.user[0])
                            } else {
                                alert(r.message)
                            }
                        })


                    } else {
                        alert(r.message)
                    }
                })

            } else {
                setisConnected(false)
                setErrorMsg("You are not an owner , Kindly get a NFT to authorize this dashboard");
                // user doesn't own the NFT.
            }

        } else {
            setErrorMsg("Select \"" + SELECTEDNETWORKNAME + "\" network in your wallet to buy the nft");
        }
    }

    return (
        <>
            <div className={"page-wrapper container"} id={"homepage"}>
                <div className="row align-items-center mt-5">
                    <div className="col-md-8 offset-md-2">
                        <div className="row text-center">
                            <div className="col-md-4 offset-md-4">
                                <br/>
                                <br/>
                                <div className={"logo-wrapper"}>
                                    <img src={logo} alt="arab watches logo" />
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>

                        <div className={"text-white mt-5 mb-3"}>
                            {userData ? <h1 className={"fw-lighter display-6 text-center mb-0"}>Hello, {userData.firstname}</h1>
                                : <h1 className={"fw-lighter display-6 text-center mb-0"}>Welcome to A22</h1>}

                        </div>
                        <div className={"text-center"}>
                            {!isConnected ? <>
                                <span className={"wallet-address-0"}>&nbsp;</span>
                            </> : <>
                                <span className={"wallet-address"}>{account}</span>
                            </>}
                        </div>
                        <div className={"text-white my-5 text-center px-md-5"}>
                            <p className={"fw-lighter px-md-5"}>
                                We are creating the largest physical NFT ecosystem linked to genuine limited editions, with unique use cases, true value, and a trusted community.
                                </p>
                        </div>
                        {!errormsg ? <>
                            {isConnected ?
                                <>

                                    <div
                                        className="d-flex justify-content-center align-items-center flex-column flex-md-row">
                                        <button
                                            className={"btn btn-link text-white text-decoration-none m-4 px-5"}>Welcome
                                        </button>
                                        <button
                                            className={"btn btn-outline-light rounded-pill text-decoration-none m-4 px-5"}
                                            onClick={() => {
                                                setModalOpen(true)
                                            }}>Please Enter
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div
                                        className="d-flex justify-content-center align-items-center flex-column flex-md-row">
                                        <button
                                            className={"btn btn-link text-white text-decoration-none m-4 px-5"}>
                                            Learn More
                                        </button>
                                        <button
                                            className={"btn btn-outline-light rounded-pill text-decoration-none m-4 px-5"}
                                            onClick={() => {
                                                connectMetaMask()
                                            }}>Login With Wallet
                                        </button>
                                    </div>
                                </>}

                        </> : <>

                            <div className={"text-white my-5 text-center px-md-5"}>
                                <p className={"fw-lighter px-md-5"}>
                                    {errormsg}
                                </p>
                            </div>
                        </>}

                        <footer className={"mt-5 pt-5 text-center"}>
                            {/*<img src={a22} alt="" className={"img-fluid"} />*/}
                            <p className={"text-white small fw-lighter pt-4"}>by continuing you would accept the <a
                                href="#" className={"text-white"}>terms and conditions</a></p>
                        </footer>

                    </div>
                </div>

            </div>
        </>
    )
}
