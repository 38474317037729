import logo from "../Assets/logo-sidenav.png"
import {useContext, useEffect, useState} from "react";
import ModalsContext from "../Utils/ModalsContext";
import waves from "../Assets/background.gif"
import tc from "../Assets/tc-new.png"
import {useNavigate} from "react-router-dom";
import pullData from "../Utils/API";
import loginContext from "../Utils/LoginContext";

export default function Profile() {
    const {setModalOpen, walletInfo, userData} = useContext(ModalsContext)
    const {setVerifiedLogin} = useContext(loginContext)

    let navigate = useNavigate();
    const [watchInfo, setWatchInfo] = useState([])

    useEffect(() => {

        if (watchInfo.length === 0) {

            walletInfo.forEach(wallet => {
                if (walletInfo) {
                    let formData = new FormData();
                    formData.append("watch_serial", wallet.watch_id)
                    pullData.getWatchInfo(formData).then(r => {
                        if (r.success === true) {
                            let watch = r.watch[0]
                            const data = {wallet: wallet, watch: watch}
                            console.log(data)
                            setWatchInfo(oldArray => [...oldArray, data]);

                        } else {
                            alert(r.message)
                        }
                    })
                }
            })
        }

    }, [watchInfo])


    console.log("wallet...", walletInfo)
    console.log("watches...", watchInfo)
    return (
        <>
            <div className={"page-wrapper container-fluid"} id={"profile"}>
                <div className="row text-center mt-3">
                    <div className="col-md-4 offset-md-4">
                        <div className={"logo-wrapper"}>
                            <img src={logo} alt="arab watches logo"/>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-end mb-5">
                    <div className={"d-flex justify-content-between w-auto"}>
                        <span className={"text-white mx-5"}>{watchInfo && watchInfo[0]?.wallet.firstname}</span>
                        <button className={"text-primary btn btn-link text-decoration-none p-0 btn-sm"}
                                onClick={() => {
                                    setVerifiedLogin(false)
                                    navigate(`/`);
                                }}>
                            Logout
                        </button>
                    </div>
                </div>
                {watchInfo && watchInfo.map((w, key) => {
                    return (
                        <div className="row h-100 mb-5 border-bottom border-dark">
                            <div className="col-md-7 waves-background position-relative">
                                <img src={waves} alt="waves background"
                                     className={"img-fluid waves position-absolute"}/>
                                <div className={"position-relative nft-wrapper"}>
                                    <img src={tc} alt="watch nft" className={"img-fluid watch"}/>
                                    <img src={`https://apis.arab-watches.com/nft-images/${w.watch.nftpath}`}
                                         alt="watch nft" className={"img-fluid nft"}/>
                                </div>

                            </div>
                            <div className="col-md-5 text-white d-flex align-items-center justify-content-center">
                                <div className={"w-100"}>
                                    <h1 className={"fw-lighter display-5 my-3"}>{w.watch.serial_number}</h1>
                                    <p className={"fw-lighter small"}>
                                        <span>Transcation Hash {JSON.parse(w.wallet.blockchain_data).transactionResponse?.transactionHash}</span>
                                        <br/>
                                        <br/>
                                        <span>Block Hash {JSON.parse(w.wallet.blockchain_data).transactionResponse?.blockHash}</span>
                                    </p>
                                    <div className={"mt-4"}>
                                        <ul className={"fw-lighter small checklist"}>
                                            <li>Your product has been linked successfuly</li>

                                            {w && w.watch.is_dispatched !== "0" &&
                                                <li>Your watch dispatched by delivery partner /
                                                    Delivery ID: <span
                                                        className={"text-decoration-underline"}>{w.tracking_number}</span>
                                                </li>
                                            }
                                            {w && w.watch.is_delivered !== "0" &&
                                                <li>Your watch has been delivered to your
                                                    <span className={"text-decoration-underline px-1"}
                                                          style={{cursor: "pointer"}}
                                                          title={userData?.full_address}>
                                                address.</span>
                                                </li>
                                            }

                                        </ul>
                                    </div>

                                    {/*<div className="d-flex align-items-center flex-column flex-md-row">*/}
                                    {/*    <button className={"btn btn-outline-light rounded-pill px-5 m-2 disabled"}>Verify a*/}
                                    {/*        product*/}
                                    {/*    </button>*/}
                                    {/*    <button className={"btn btn-outline-light rounded-pill px-5 m-2 disabled"}>Report as*/}
                                    {/*        lost*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>

                            </div>
                        </div>
                    )
                })}


                <footer className={"text-center mt-5"}>
                    <p className={"small text-white fw-lighter"}>View <a href="#" className={"text-white"}>terms and
                        coditions & Privacy policy</a></p>
                </footer>

            </div>
        </>
    )
}